import React, { useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';
import './App.css';

function App() {
  const pixiContainerRef = useRef(null);

  useEffect(() => {
    const app = new PIXI.Application({
      width: window.innerWidth,
      height: window.innerHeight,
      resizeTo: window,
      transparent: true
    });

    pixiContainerRef.current.appendChild(app.view);

    const video = document.createElement('video');
    video.muted = true;
    video.loop = true;
    video.playsInline = true;
    video.src = '/api/video';

    video.addEventListener('canplay', () => {
      video.play().catch(e => console.error("Error playing video:", e));
    });

    const videoTexture = PIXI.Texture.from(video);
    const videoSprite = new PIXI.Sprite(videoTexture);

    // Load displacement map as a static texture
    const displacementTexture = PIXI.Texture.from('/api/displacement-map');
    const displacementSprite = new PIXI.Sprite(displacementTexture);

    // Create displacement filter with the static sprite
    const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
    displacementFilter.scale.set(0); // Start with no displacement

    function resize() {
      app.renderer.resize(window.innerWidth, window.innerHeight);

      if (video.videoWidth && video.videoHeight) {
        const scale = Math.max(app.screen.width / video.videoWidth, app.screen.height / video.videoHeight);
        videoSprite.scale.set(scale);
        videoSprite.position.set(
          (app.screen.width - videoSprite.width) / 2,
          (app.screen.height - videoSprite.height) / 2
        );
      } else {
        videoSprite.width = app.screen.width;
        videoSprite.height = app.screen.height;
        videoSprite.position.set(0, 0);
      }
    }

    app.stage.addChild(videoSprite);
    app.stage.filters = [displacementFilter];
    app.stage.addChild(displacementSprite);

    resize();
    
    const maxDisplacementIntensity = 100;
    let pointerX = 0;
    let pointerY = 0;

    function animate() {
      const displacementX = (pointerX / app.screen.width - 0.5) * maxDisplacementIntensity * 2;
      const displacementY = (pointerY / app.screen.height - 0.5) * maxDisplacementIntensity * 2;
      displacementFilter.scale.set(displacementX, displacementY);

      // Resize displacement map
      const displacementScale = Math.max(
        app.screen.width / displacementSprite.texture.orig.width,
        app.screen.height / displacementSprite.texture.orig.height
      );
      displacementSprite.scale.set(displacementScale);
      displacementSprite.position.set(
        (app.screen.width - displacementSprite.width) / 2,
        (app.screen.height - displacementSprite.height) / 2
      );

      requestAnimationFrame(animate);
    }

    animate();

    function updatePointerPosition(x, y) {
      pointerX = x;
      pointerY = y;
    }

    // Mouse events
    window.addEventListener('mousemove', (event) => {
      updatePointerPosition(event.clientX, event.clientY);
    });

    // Touch events
    window.addEventListener('touchstart', (event) => {
      if (event.touches.length > 0) {
        updatePointerPosition(event.touches[0].clientX, event.touches[0].clientY);
      }
    });

    window.addEventListener('touchmove', (event) => {
      if (event.touches.length > 0) {
        updatePointerPosition(event.touches[0].clientX, event.touches[0].clientY);
      }
    });

    window.addEventListener('resize', resize);

    return () => {
      app.destroy(true, true);
    };
  }, []);

  return (
    <div>
      <div id="pixi-container" ref={pixiContainerRef}></div>
      <div className="content"></div>
    </div>
  );
}

export default App;